/*プロフィール*/
#about .profileCard {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 30px;
}

#about .profileImage {
  height: 200px;
  width: 200px;
  margin-right: 15px;
}

#about .profileText {
  text-align: left;
  margin: auto 0 auto 0;
}

/*リンク*/
#sns a {
  color: blue;
  /* border: solid; */
}

/*コンテンツ*/
#homePageContents p {
  text-align: center;
}

/*装飾*/
.border {
  margin: auto;
  margin-bottom: 10px;
  background: #000000; /*線の色指定*/
  width: 80%; /*線の幅指定*/
  height: 3px; /*線の高さ指定*/
}
