@charset "UTF-8";
:root {
  --black-color: #010000;
  --white-color: #ffffff;
  --lightgray-color: #e6e6e6;
}

.App {
  font-size: 18px;
  height: 100vh;
  width: 100%;
}

html {
  font-size: 100%;
}
body {
  color: #383e45;
  font-size: 0.9rem;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
}
li {
  list-style: none;
}
.site-title {
  width: 120px;
  line-height: 1px;
  padding: 10px 0;
}
.site-title a {
  display: block;
}
.section-title {
  display: inline-block;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 60px;
  border-bottom: solid 1px #383e45;
}
.content-title {
  font-size: 1rem;
  margin: 10px 0;
}

header .logo h3 {
  margin: 0;
  padding: 0;
  color: white;
}

.mainContents {
  padding-top: 90px;
}

nav ul {
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style: none;
  display: inline-block;
  margin-right: 30px;
}

nav ul li a {
  font-size: 19px;
  text-decoration: none;
  color: var(--white-color);
  transition: all 0.2s;
}

nav ul li a:hover {
  color: #48b4af;
}
