header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: black;
  color: white;
  height: 90px;
  position: fixed;
  z-index: 10;
}

#header.scroll {
  padding: 10px 40px;
}
